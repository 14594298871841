const randomTextWraps = [
  ...document.querySelectorAll<HTMLElement>('[data-randomizer-element]'),
  ...document.querySelectorAll<HTMLElement>('.text-link'),
]

if (randomTextWraps && randomTextWraps !== null) {
  import('./RandomizeString')
    .then((module) => {
      randomTextWraps.forEach((element) => {
        const childElement = element.querySelector('[data-randomizer-element-child]') as HTMLElement

        if (childElement) {
          const randomizer = module.default(element, childElement)
          randomizer?.start()
        } else {
          const isInfinite = element.getAttribute('data-randomizer-infinite') ?? false
          const randomizer = module.default(element, null, {
            infinite: isInfinite === 'true' ? true : false,
          })
          randomizer?.start()
        }
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

const afterEffectsStrings = document.querySelectorAll<HTMLElement>('[data-after-effects-string]')
if (afterEffectsStrings && afterEffectsStrings !== null) {
  import('./AfterEffectsString')
    .then((module) => {
      module.default(afterEffectsStrings)
    })
    .catch((err) => {
      console.log(err)
    })
}
