// data-js="toggleMenu"  - toggles the menu which is = data-js="SiteMobileMenu"
// Create a function that handels the toggle of the menu and toggles the "is-open" class.

function MenuToggle() {
  const mobileMenu = document.querySelector('[data-js="SiteMobileMenu"]')
  const openButton = document.querySelector('[data-js="OpenMenu"]')
  const closeButton = document.querySelector('[data-js="CloseMenu"]')

  openButton.addEventListener('click', () => {
    mobileMenu.classList.add('is-open')
  })

  closeButton.addEventListener('click', () => {
    mobileMenu.classList.remove('is-open')
  })
}

export default MenuToggle
