// Our main CSS
import '../css/app.css'

import LazyLoad from 'vanilla-lazyload'
import './StringAnimations/index'

// import { gsap } from 'gsap'
// import { ScrollTrigger } from 'gsap/ScrollTrigger'

// gsap.registerPlugin(ScrollTrigger)

// let panels = gsap.utils.toArray('[data-parallax="section"]')

// panels.forEach((panel, i) => {
//   ScrollTrigger.create({
//     trigger: panel,
//     start: 'top top',
//     pin: true,
//     pinSpacing: false,
//     snap: false,
//   })
// })

const lazyLoad = new LazyLoad({
  elements_selector: '.lazy',
  threshold: -50,
})

import MenuToggle from './components/MenuToggle'
MenuToggle()

const popupButtons = document.querySelectorAll('[data-toggle-popup]')
if (popupButtons && popupButtons !== null) {
  import('./components/TogglePopup')
    .then((module) => {
      popupButtons.forEach((element) => {
        module.default(element as HTMLElement)
      })
    })
    .catch((err) => {
      console.log(err)
    })
}

const header = document.querySelector('[data-js="SiteHeader"]') as HTMLElement
if (header && header !== null) {
  import('./components/HeaderScroll')
    .then((module) => {
      module.SiteHeaderScroll()
    })
    .catch((err) => {
      console.log(err)
    })
}

const FullWidthSliders = document.querySelectorAll<HTMLElement>('[data-js="FullWidthSlider"]')
if (FullWidthSliders && FullWidthSliders !== null) {
  import('./components/FullWidthSlider')
    .then((module) => {
      module.default(FullWidthSliders as NodeListOf<HTMLElement>)
    })
    .catch((err) => {
      console.log(err)
    })
}

const AccordionTriggers = document.querySelectorAll<HTMLElement>('[data-js="AccordionTrigger"]')
if (AccordionTriggers && AccordionTriggers !== null) {
  import('./components/Accordion')
    .then((module) => {
      module.default(AccordionTriggers as NodeListOf<HTMLElement>)
    })
    .catch((err) => {
      console.log(err)
    })
}

const scrollToTop = document.querySelector<HTMLElement>('[data-js="ScrollToTop"]')
scrollToTop?.addEventListener('click', () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
})
